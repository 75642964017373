import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Icon.styles.scss'

import Agriculture from './images/agriculture.inline.svg'
import Balance from './images/balance.inline.svg'
import Cycle from './images/cycle.inline.svg'
import Eyes from './images/eyes.inline.svg'
import Fish from './images/fish.inline.svg'
import Houses from './images/houses.inline.svg'
import Land from './images/land.inline.svg'
import Mountains from './images/mountains.inline.svg'
import People from './images/people.inline.svg'
import Person from './images/person.inline.svg'
import Regeneration from './images/regeneration.inline.svg'
import River from './images/river.inline.svg'
import Trees from './images/trees.inline.svg'
import Energy from './images/energy.inline.svg'
import Village from './images/village.inline.svg'
import Vision from './images/vision.inline.svg'
import Waving from './images/waving.inline.svg'

const ICON_TYPES = {
  agriculture: { width: '40%', image: <Agriculture /> },
  balance: { width: '65%', image: <Balance /> },
  cycle: { width: '70%', image: <Cycle /> },
  energy: { width: '75%', image: <Energy /> },
  eyes: { width: '90%', image: <Eyes /> },
  fish: { width: '80%', image: <Fish /> },
  houses: { width: '65%', image: <Houses /> },
  land: { width: '100%', image: <Land /> },
  mountains: { width: '100%', image: <Mountains /> },
  people: { width: '75%', image: <People /> },
  person: { width: '50%', image: <Person /> },
  regeneration: { width: '70%', image: <Regeneration /> },
  river: { width: '85%', image: <River /> },
  trees: { width: '70%', image: <Trees /> },
  village: { width: '80%', image: <Village /> },
  vision: { width: '80%', image: <Vision /> },
  waving: { width: '55%', image: <Waving /> },
}

const Icon = ({ uid }) => {
  const icon = ICON_TYPES[uid] || {}

  if (icon) {
    return (
      <div className={styles.icon} style={{ width: icon.width }}>
        {icon.image}
      </div>
    )
  }

  return null
}

Icon.defaultProps = {
  uid: 'none',
}

Icon.propTypes = {
  uid: PropTypes.string,
}

export { Icon, ICON_TYPES }
